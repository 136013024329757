import React from "react"
import { SocialProvider, SocialLink } from "@mui-treasury/components/socialLink"
import { usePoofSocialLinkStyles } from "@mui-treasury/styles/socialLink/poof"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  social: {
    display: "flex",
    justifyContent: "center",
    marginTop: "2rem",
  },
})

const Social = () => {
  const styles = useStyles()
  return (
    <div className={styles.social}>
      <SocialProvider useStyles={usePoofSocialLinkStyles}>
        <SocialLink
          href="https://github.com/Sma-source"
          brand={"GithubCircle"}
        />
        <SocialLink
          href="https://www.linkedin.com/in/sma%C3%AFn-rabhi/"
          brand={"LinkedInSquare"}
        />
        <SocialLink href="https://twitter.com/smaDev1" brand={"Twitter"} />
        <SocialLink href="mailto:smain.rabhi@gmail.com" brand={"Envelope"} />
      </SocialProvider>
    </div>
  )
}

export default Social
