import React from "react"
import { Link } from "gatsby"

import { NavItem, NavMenu } from "@mui-treasury/components/menu/navigation"

const Nav = () => {
  return (
    <>
      <NavMenu component="nav" gutter={"10px"} className="nav-styles">
        <NavItem as={Link} to={"/"}>
          Blog
        </NavItem>
        <NavItem as={Link} to={"/about"}>
          A propos
        </NavItem>
        <NavItem as={Link} to={"/contact"}>
          Contact
        </NavItem>
      </NavMenu>
    </>
  )
}

export default Nav
